import {
  getHours, getMinutes, add, formatISO,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Booking } from '../features/bookings/bookingsAPI';
import { PickerBooking } from '../models/picker_booking';

export const nthNumber = (num: number) => `${num}${(num > 0
  ? ['th', 'st', 'nd', 'rd'][
    (num > 3 && num < 21) || num % 10 > 3 ? 0 : num % 10
  ]
  : '')}`;

export const toISOString = (date: Date) => formatISO(utcToZonedTime(new Date(date), 'Australia/Melbourne'));

export const removeNonNumericEdges = (str: string | undefined | null): string | undefined => {
  if (str === undefined || str === null || str.trim() === '') {
    return undefined;
  }
  // Remove non-numeric characters from the beginning or end
  let cleanedStr = str.replace(/^[^gG0-9]+|[^0-9abcdefghABCDEFGH]+$/g, '');
  // Remove leading zeros
  cleanedStr = cleanedStr.replace(/^0+/, '');
  return cleanedStr.toUpperCase();
};

// returns 9am
export const pineAppleTime = (date: Date) => {
  const zonedDate = utcToZonedTime(date, 'Australia/Melbourne');
  let hours = getHours(zonedDate);
  const minutes = getMinutes(zonedDate);
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const strTime = `${hours}:${minutesStr} ${ampm}`;
  return strTime;
};

export const pineAppleTimePlus4 = (date: Date) => pineAppleTime(add(date, { hours: 4 }));

export const getNameOfTheDay = (date: Date) => {
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const day = weekday[date.getDay()];
  return day;
};

export const getNameOfTheMonth = (date: Date) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  return monthNames[date.getMonth()];
};

export const pineAppleDay = (date: Date) => `${getNameOfTheDay(date)} ${nthNumber(Number(date.getDate()))} ${getNameOfTheMonth(date)}`;

export const filterDuplicates = (bookings: Array<Booking>): Array<PickerBooking> => {
  let preTime = '';
  return bookings.map((booking: Booking) => {
    if (booking.start_datetime_original_iso_str !== preTime) {
      const start = new Date(booking.start_datetime_original_iso_str);
      const end = new Date(booking.end_datetime_original_iso_str);
      const event = {
        event_id: booking.id,
        title: 'Arrival between',
        start,
        end,
      };
      preTime = booking.start_datetime_original_iso_str;
      return event;
    }

    return {
      event_id: booking.id,
      title: 'Duplicate',
      start: new Date(booking.start_datetime_original_iso_str),
      end: new Date(booking.end_datetime_original_iso_str),
    };
  }).filter((booking: PickerBooking) => booking.title !== 'Duplicate');
};

export function ordinalSuffixOf(i: number) {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export function humanizeNumber(value: number): string {
  value = Math.floor(value);
  const ones = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];
  const tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  const numString = value.toString();

  if (value < 0) throw new Error('Negative numbers are not supported.');

  if (value === 0) return 'zero';

  // the case of 1 - 20
  if (value < 20) {
    return ones[value];
  }

  if (numString.length === 2) {
    return `${tens[Number(numString[0])]} ${ones[Number(numString[1])]}`;
  }

  // 100 and more
  if (numString.length === 3) {
    if (numString[1] === '0' && numString[2] === '0') {
      return `${ones[Number(numString[0])]} hundred`;
    }
    return (
      `${ones[Number(numString[0])]
      } hundred and ${
        humanizeNumber(+(numString[1] + numString[2]))}`
    );
  }

  if (numString.length === 4) {
    const end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return `${ones[Number(numString[0])]} thousand`;
    if (end < 100) {
      return (
        `${ones[Number(numString[0])]
        } thousand and ${
          humanizeNumber(end)}`
      );
    }
    return (
      `${ones[Number(numString[0])]} thousand ${humanizeNumber(end)}`
    );
  }
  return '';
}
