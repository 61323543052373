import httpClient from "../../http/client";
import { arrivyBookingID, backendURL } from "../../lib/config";

export type Booking = {
  updated: string;
  is_disabled: boolean;
  start_datetime: string;
  end_datetime: string;
  end_datetime_original_iso_str: string;
  id: number;
  booking_id: number;
  url_safe_id: string;
  start_datetime_original_iso_str: string;
};

type AddrResponse = {
  booking_slots: Array<Booking>;
};

export function fetchBookings(state: string, date: Date) {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 3, 1);

  const isoDateTime = new Date(
    firstDay.getTime() - firstDay.getTimezoneOffset() * 60000,
  ).toISOString();
  const fr = isoDateTime;

  const isoDateTimeToDate = new Date(
    lastDay.getTime() - lastDay.getTimezoneOffset() * 60000,
  ).toISOString();
  const to = isoDateTimeToDate;

  return httpClient.get<AddrResponse>(
    new URL(`/api/v1/pineapple/bookings/${state}?from=${fr}&to=${to}`, backendURL).toString(),
    {
      headers: {
        Accept: "application/json",
      },
    },
  );
}

export function fetchBooking(state: string, date: Date) {
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
  );
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
  );

  const isoDateTime = new Date(
    firstDay.getTime() - firstDay.getTimezoneOffset() * 60000,
  ).toISOString();

  const fr = isoDateTime;

  const isoDateTimeToDate = new Date(
    lastDay.getTime() - lastDay.getTimezoneOffset() * 60000,
  ).toISOString();

  const to = isoDateTimeToDate;

  return httpClient.get<AddrResponse>(
    new URL(`/api/v1/pineapple/bookings/${state}?from=${fr}&to=${to}`, backendURL).toString(),
    {
      headers: {
        Accept: "application/json",
      },
    },
  );
}
