import React, { PropsWithChildren } from 'react';

interface PineappleAlertProps extends React.HTMLProps<HTMLDivElement> {
  className?: string
}

const PineappleAlert = (
  { children, className, ...props }: PropsWithChildren<PineappleAlertProps>,
) => (
  <div
    className={`bg-secondary my-3 p-2 text-center rounded-pill ${className}`}
    {...props}
  >
    { children }
  </div>
);

export default PineappleAlert;
